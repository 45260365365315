import * as React from "react"
import MainLayout from "src/components/layoutmain";
import LayoutCentered from "src/components/layoutcentered";
import {Link} from "gatsby";

// markup
const IndexPage = () => {
  const pageProps = {
    title: "Le live bar - Accueil",
    menuPageTitle: "Présentation",
    description:
      "Le live bar, un bar différent, connecté, responsable, durable qui fait vivre la culture par les concerts et l'art. Un bar branché et dans l'air du temps.",
  };

  return (
    <MainLayout pageProps={pageProps}>
      <LayoutCentered className={"home"}>
        <div className={"menu-or-events-container"}>
          <Link to="/carte" className={"menu-or-events-container--left"}>
            <h2>Boire <br/>&<br/> Manger</h2>
            <div className={"icon-lelivebar-engrenage"}/>
          </Link>
          <Link to="/evenements" className={"menu-or-events-container--right"}>
            <h2>Voir&nbsp;les<br/> évènements</h2>
            <div className={"icon-lelivebar-engrenage"}/>
          </Link>
          <div className={"menu-or-events-container--center"}>
            <h1>Tu préfères</h1>
            <div className={"menu-or-events-container--center-arrow"}>
              <div className={"icon-lelivebar-arrow-design-right"} />
              <div className={"icon-lelivebar-arrow-design-right"} />
            </div>
          </div>
        </div>

        <p className={"lelivebar-is-welcoming"}>
          <span>LE LIVE BAR</span> vous accueille à Nantes <br/>
          dans un lieu fait pour
        </p>

        <p className={"lelivebar-action"}>
          dessiner, exposer, jouer, se poser, regarder, délirer,<br/>
          se promener, discuter, chanter
        </p>

        <p className={"lelivebar-express-yourself"}>En bref s'exprimer !</p>
      </LayoutCentered>
    </MainLayout>
  )
}

export default IndexPage
